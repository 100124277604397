import React from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
// styles
import clsx from 'clsx';
import itemStyles from '../components/item/cases.module.css';
import styles from './bacbone.module.css';
// components
import Layout from '../components/item/layout';
import Blockquote from '../components/blockquote';
import Team from '../components/team';
import Resume from '../components/resume';
// other
import { meta } from '../../../metaData';

function BackBoneCase() {
  const data = useStaticQuery(graphql`
    query {
      timDeJonge: file(relativePath: { eq: "tim-de-jonge.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 215, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen1: file(relativePath: { eq: "backbone-screen-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1980, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen2: file(relativePath: { eq: "backbone-screen-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1980, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen3: file(relativePath: { eq: "backbone-screen-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1980, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen4: file(relativePath: { eq: "backbone-screen-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 520, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen5: file(relativePath: { eq: "backbone-screen-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 830, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen6: file(relativePath: { eq: "backbone-screen-6.png" }) {
        childImageSharp {
          fluid(maxWidth: 546, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen7: file(relativePath: { eq: "backbone-screen-7.png" }) {
        childImageSharp {
          fluid(maxWidth: 1085, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen8: file(relativePath: { eq: "backbone-screen-8.png" }) {
        childImageSharp {
          fluid(maxWidth: 878, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen9: file(relativePath: { eq: "backbone-screen-9.png" }) {
        childImageSharp {
          fluid(maxWidth: 543, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen10: file(relativePath: { eq: "backbone-screen-10.png" }) {
        childImageSharp {
          fluid(maxWidth: 470, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen11: file(relativePath: { eq: "backbone-screen-11.png" }) {
        childImageSharp {
          fluid(maxWidth: 622, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen12: file(relativePath: { eq: "backbone-screen-12.png" }) {
        childImageSharp {
          fluid(maxWidth: 430, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen13: file(relativePath: { eq: "backbone-screen-13.png" }) {
        childImageSharp {
          fluid(maxWidth: 676, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen14: file(relativePath: { eq: "backbone-screen-14.png" }) {
        childImageSharp {
          fluid(maxWidth: 424, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen15: file(relativePath: { eq: "backbone-screen-15.png" }) {
        childImageSharp {
          fluid(maxWidth: 430, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen16: file(relativePath: { eq: "backbone-screen-16.png" }) {
        childImageSharp {
          fluid(maxWidth: 676, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen17: file(relativePath: { eq: "backbone-screen-17.png" }) {
        childImageSharp {
          fluid(maxWidth: 424, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout
      navBtn="#6A9F1F"
      score={['Events Management', 'Netherlands', 'June, 2018', 'Ongoing']}
      layoutClassName={styles.main}
      headerTitle="Backbone International&nbsp;— revolutionizing events management sector"
      subTitle="How Brocoders helped to streamline the business efficiency and transform the workflow with an innovative feature-rich platform"
      className={styles.header}
      contentClassName={styles.content}
      metaData={meta.backbone}
    >
      <div className={itemStyles.inner}>
        <p className={itemStyles.preamble}>
          Initially founded in the Netherlands back in 2010, Backbone International has been
          providing its services as a management and technical partner for the clients that are in
          the business of creating, organizing or financing events. Led by the ambition to maximize
          its impact and cover the global market, Backbone established additional offices in North
          America, China and Indonesia.
        </p>
        <p className={itemStyles.paragraph}>
          The Brocoders team managed to build advantageous long-term partnerships with Backbone
          International. Since June 2018, both our teams have been working hard to collaboratively
          create a top-notch solution that could reshape the Backbone working environment, while
          guaranteeing rock-solid safety and top quality of the provided services.
        </p>
      </div>
      <Img fluid={data.screen3.childImageSharp.fluid} className={styles.screen3} alt="" />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>The situation</h2>
        <p className={itemStyles.paragraph}>
          You might end up with challenging tasks when managing and planning several events that
          sometimes happen to be held in the same month or even week. To stay out of the curve these
          days, it's vital to stick to state-of-the-art technology that can take the lion's share of
          time-consuming tasks from your to-do list. Such an approach, in turn, lets you focus on
          more important business goals.
        </p>
        <p className={itemStyles.paragraph}>
          As a technical production company, Backbone International required a robust solution that
          could help in planning, organizing and executing events of any type in a highly efficient
          and timely manner.
        </p>
        <p className={itemStyles.paragraph}>
          That said, Brocoders was approached by the representatives of Backbone to develop an
          all-in-one platform where every team member could monitor the schedules, understand what
          and when should be done to deliver a high-quality service.
        </p>
        <p className={itemStyles.paragraph}>
          To come up with a custom-designed product that meets all the client's requirements, we
          started with several workshops in the first place. During the discussions, we managed to
          define our priorities as well as the scope of the core app's functionality:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>
            Develop a tool that makes it easy to create and share schedules among the Backbone team.
          </li>
          <li>
            Allow for managing the data regarding suppliers, clients and attendees involved in the
            production.
          </li>
          <li>
            Make it possible for the team members to work with the event schedules based on their
            authority levels.
          </li>
          <li>
            Provide both suppliers and clients with an insightful, easy-to-use tool to smoothly
            monitor their schedules.
          </li>
        </ul>
      </div>
      <section className={styles.wp}>
        <div className={itemStyles.inner}>
          <h2 className={itemStyles.title}>Working process</h2>
          <p className={itemStyles.paragraph}>
            To investigate the market and analyze a bunch of crucial data, we engaged our on-site
            business analysts from the very beginning of the project. We employed the iterative
            development methodology to allow for easier testing and debugging, as well as to ensure
            the project's viability and changes flexibility.
          </p>
          <p className={itemStyles.paragraph}>
            Backed by the discussed and documented requirements, we created a high fidelity
            prototype to eventually come up with a multi-functioning and easy-to-work-with platform.
          </p>
        </div>
        <div className={styles.imgGroup}>
          <Img
            fluid={data.screen4.childImageSharp.fluid}
            className={styles.screen4}
            style={{ position: 'absolute', left: 0, right: '60%', top: 0 }}
            alt=""
          />
          <Img
            fluid={data.screen6.childImageSharp.fluid}
            className={styles.screen6}
            style={{ position: 'absolute', left: '60%', right: 0, bottom: 0 }}
            alt=""
          />
          <div className={clsx('inner', itemStyles.inner)}>
            <Img
              fluid={data.screen5.childImageSharp.fluid}
              className={styles.screen5}
              style={{ transform: 'scale(1.1)' }}
              alt=""
            />
          </div>
        </div>
        <div className={itemStyles.inner}>
          <p className={itemStyles.paragraph}>
            Based on the Scrum model, we provided our partner with small increments every two weeks
            to acquire invaluable feedback. Driven by such a client-centric approach, we could stay
            on the same page with the Backbone team throughout the entire development process while
            enabling seamless improvements.
          </p>
          <h2 className={itemStyles.title}>Key challenges</h2>
          <p className={itemStyles.paragraph}>
            According to the most crucial tasks set before us by the client, we had to empower the
            solution with the following features:
          </p>
          <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
            <li>Full-fledged visual displaying of schedules.</li>
            <li>Possibility to create an event with a basic description at the first stages.</li>
            <li>Adding/editing/deleting main activities for each event.</li>
            <li>
              Team members can combine activities in groups in case these are similar or repeatable
              during the event. Custom logic for the groups of activities is provided.
            </li>
            <li>
              Effective management of additional activities that should be performed by the exact
              supplier.
            </li>
            <li>Displaying the order of all added activities.</li>
            <li>
              Displaying the type and scheduled time of the equipment utilized by every supplier.
            </li>
          </ul>
          <p className={itemStyles.paragraph}>
            Besides, we had to develop a user-friendly design concept to provide a clear, insightful
            and eye-pleasing scheduling interface for all parties at the same time, hence ensuring
            the outstanding user experience. To accomplish this task, we assigned a project manager
            and a UI/UX designer to the project.
          </p>
        </div>
        <div className={styles.artSection}>
          <Img fluid={data.screen7.childImageSharp.fluid} className={styles.screen7} alt="" />
          <div className={itemStyles.inner}>
            <Img fluid={data.screen8.childImageSharp.fluid} className={styles.screen8} alt="" />
            <Img fluid={data.screen9.childImageSharp.fluid} className={styles.screen9} alt="" />
            <Img fluid={data.screen10.childImageSharp.fluid} className={styles.screen10} alt="" />
          </div>
          <Img
            fluid={data.screen11.childImageSharp.fluid}
            className={styles.screen11}
            style={{ position: 'absolute' }}
            alt=""
          />
        </div>
        <Blockquote
          text="“It seemed clear that Backbone could profit from an application that supports a large number of the processes, so everyone could find and save their information in the same place. The importance of the applications like these not only lies in advantages in regard to scalability, but also in more insights, unity and efficiency, and therefore a lower workload.“"
          img={data.timDeJonge.childImageSharp.fluid}
          author={
            <React.Fragment>
              <b>Tim de Jonge</b> <br />
              Project Coordinator at Backbone International
            </React.Fragment>
          }
        />

        <div className={itemStyles.inner}>
          <h2 className={itemStyles.title}>Our team</h2>
          <p className={itemStyles.paragraph}>
            To cope with all the field-specific challenges, Brocoders assembled a self-motivated
            team of seasoned professionals with years of software development expertise:
          </p>
          <Team
            color="#537A1C"
            list={[
              { title: 'Frontend\ndevelopers', count: 2 },
              { title: 'Backend\ndeveloper', count: 1 },
              { title: 'Product\ndesigner', count: 1 },
              { title: 'QA\nengineer', count: 1 },
              { title: 'Project\nmanager', count: 1 },
            ]}
          />
          <p className={itemStyles.paragraph}>
            Once we covered the MVP stage and acquired approval for the proposed design concept, we
            moved on to evaluating the scope of work, after which the major features and tasks were
            split into milestones. Based on our business priorities, we ended up with three
            milestones eventually.
          </p>
          <Resume
            services={['dedicatedTeam', 'devOps']}
            technology={['react', 'nodejs', 'figma']}
          />
        </div>
        <Img fluid={data.screen1.childImageSharp.fluid} className={styles.screen1} alt="" />
        <div className={itemStyles.inner}>
          <h2 className={itemStyles.title}>Delivered solution</h2>
          <p className={itemStyles.paragraph}>
            The Brocoders team developed a reliable feature-rich platform for managing numerous
            schedules and activities during the events of any type and size. We provided full-cycle
            maintenance starting from the initial idea to product-market fit to the final phase of
            customer support once the product was launched.
          </p>
          <p className={itemStyles.paragraph}>
            Thanks to the agile-related frameworks, our dedicated team managed to comply with
            deadlines while meeting all the customer's requirements and needs.
          </p>
          <p className={itemStyles.paragraph}>
            The client received a powerful web-driven platform to streamline the primary workflow
            while other users ended up with hassle-free tools that let them monitor and facilitate
            their business goals during the events.
          </p>
          <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
            <li>
              Users can automate and simplify a planning strategy with easy-to-use dashboards that
              can be shared with other stakeholders.
            </li>
            <li>All users can enjoy a comfy in-built transport schedule.</li>
            <li>
              The Backbone team members can access a summary page that contains full-fledged data on
              every event activity or program (one single page for one single event).
            </li>
            <li>
              Users can only access and receive the information they are authorized to by the event
              provider.
            </li>
            <li>
              The Backbone team members, along with suppliers, can check and monitor the data
              regarding the equipment or machinery that is utilized during the event.
            </li>
          </ul>
        </div>
      </section>
      <section className={styles.result}>
        <div className={styles.resultImage}>
          <div className={styles.img1}>
            <Img fluid={data.screen12.childImageSharp.fluid} className={styles.screen12} alt="" />
          </div>
          <div className={styles.img2}>
            <Img fluid={data.screen13.childImageSharp.fluid} className={styles.screen13} alt="" />
          </div>
          <div className={styles.img3}>
            <Img fluid={data.screen14.childImageSharp.fluid} className={styles.screen14} alt="" />
          </div>
        </div>
        <div className={clsx(styles.resultImage, styles.translate)}>
          <div className={styles.img1}>
            <Img fluid={data.screen15.childImageSharp.fluid} className={styles.screen12} alt="" />
          </div>
          <div className={styles.img2}>
            <Img fluid={data.screen16.childImageSharp.fluid} className={styles.screen13} alt="" />
          </div>
          <div className={styles.img3}>
            <Img fluid={data.screen17.childImageSharp.fluid} className={styles.screen14} alt="" />
          </div>
        </div>
        <div className={clsx(itemStyles.inner, styles.innerstyles)}>
          <h2 className={clsx(itemStyles.title, styles.resultTitle)}>The results</h2>
          <p className={itemStyles.paragraph}>
            To date, more than 20 events are managed via the Backbone platform, where about 90
            suppliers take part in different activities required for a successful organization of
            various events across the globe.
          </p>
          <p className={itemStyles.paragraph}>
            At the moment, we are planning the next iteration and the scope of work for another
            milestone. Based on the end-users' feedback, we now have a list of additional features
            designed to be added in the upcoming versions.
          </p>
          <p className={itemStyles.paragraph}>
            Driven by a client-oriented business approach, we are eager to help Backbone
            International keep up with the times by enriching the existing solution with some extra
            features. It is our primary goal to ensure that our partners can reach the best possible
            outcomes while delivering fascinating services that users love and enjoy.
          </p>
        </div>
        <Img fluid={data.screen2.childImageSharp.fluid} className={styles.screen2} alt="" />
      </section>
    </Layout>
  );
}

export default BackBoneCase;
